import Skeleton from 'react-loading-skeleton'

// Import the CSS for the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css'

const AchieveSkeleton = ({ variant, ...props }) => {
  return (
    <div
      className={
        'data-[variant="transparent"]:[&>:first-child]:[&>:first-child]:bg-[rgba(29_37_47_/_11%)] [--custom-highlight-background:linear-gradient(_90deg,rgba(29_37_47_/_11%)_40%,rgba(29_37_47_/_11%)_50%,rgba(29_37_47_/_11%)_60%_)]'
      }
      data-variant={variant}
    >
      <Skeleton {...props} />
    </div>
  )
}

export default AchieveSkeleton
export { AchieveSkeleton }
